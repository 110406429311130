/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --header-logo-width: 194px;
    --header-logo-height: 24px;

    --header-height: calc(44px + var(--promo-bar-mobile-height));
    --header-nav-height: 60px;
    --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + env(safe-area-inset-top));

    @include desktop {
        --header-top-menu-height: 32px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }
}

.Header {
    border-bottom: 0;
    filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.12));
    height: 100%;
    margin-bottom: 16px;
    position: relative;

    @include mobile {
        box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.12);
        filter: none;
    }

    &_isCheckout {
        @include desktop {
            min-height: auto;
        }

        .Header-Nav {
            @include desktop {
                min-height: 84px;
                margin: 0 auto;
                padding: 0 32px;
            }
        }

        .Header-MyAccount {
            @include desktop {
                min-width: 200px;
            }
        }

        .Header-LogoWrapper {
            @include desktop {
                position: absolute;
                left: 32px;
            }
        }

        .Header-TopMenu {
            @include desktop {
                padding: 0;
            }
        }
    }

    &-GoToStore {
        letter-spacing: 0px;
        color: #00183F;
        font-size: 14px;
        font-weight: bold;

        @include mobile {
            font: normal normal bold 12px/15px 'Lato';
        }
    }

    &-Title {

        &,
        span {
            @include mobile {
                font-weight: 600;
                font-size: 16px;
                color: $font-color-4;
            }
        }
    }

    &-Wrapper {
        margin-bottom: 0;

        @include mobile {
            position: fixed;
            top: 0;
            width: 100%;
        }

        &_isHideLayout {
            .Header {
                background-color: #E8F1FF;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0;
                filter: none;

                @include mobile {
                    box-shadow: none;
                }
            }

            +.Router-MainItems {
                @include desktop {
                    overflow-x: hidden;
                }

                .CmsPage-Wrapper {
                    max-width: 1088px;
                    margin-bottom: 48px;
                }
            }

            +.NavigationTabs {
                @include mobile {
                    display: none;
                }
            }

            &,
            .Header-Nav {
                min-height: 100px;

                @include mobile {
                    min-height: 80px;
                }
            }

            .Header-Nav {
                margin: 0;
                height: 100%;
                display: flex;
                justify-content: end;
                max-width: 1088px;
                padding: 0;

                @include mobile {
                    padding: 0 36px;
                }

                .Image {
                    background-color: transparent;
                }
            }

            .Header-Button_type_back {
                display: none;
            }

            .Header-LogoWrapper {
                display: flex;
                align-items: center;
                opacity: 1;
                margin: 0 auto 0 0;
            }
        }
    }

    &-Button {
        &_type_minicart {
            margin-left: 0 !important;
        }

        &_type_back {
            position: relative;

            svg path {
                stroke: $font-color-1;
            }

            &::after {
                content: '';
                display: block;
                width: 1px;
                height: 44px;
                background-color: $font-color-3;
                position: absolute;
                right: -18px;
                top: -10px;
            }
        }
    }

    &-CheckOrderStatus {
        font-weight: 600;
        text-transform: uppercase;

        &:hover {
            color: $primary-color-1;
        }
    }

    &-Contact {
        align-items: center;
        display: inline-flex;
        gap: 8px;
        line-height: 20px;

        a {
            font-weight: 400;
        }

        &:hover {
            a {
                color: $primary-color-1;
            }

            path {
                stroke: $primary-color-1;
            }
        }

        &:nth-child(3) {
            text-transform: uppercase;
        }
    }

    &-LeftContent {
        display: flex;
        gap: 20px;
    }

    &-MyAccountWrapper {
        align-items: center;
        color: $font-color-1;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;

        @include mobile {
            font-size: 16px
        }

        &:hover {
            color: $primary-color-1;

            path {
                stroke: $primary-color-1;
            }
        }

        &_isCheckout {
            display: flex;
            text-transform: lowercase;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $font-color-2;
            position: relative;
            right: -6px;
            margin-left: auto;

            svg {
                order: 1;
                margin-left: 10px;
            }

            &.Header-MyAccountWrapper_isLoggedIn {
                text-transform: initial;
            }
        }
    }

    &-RightContent {
        align-items: center;
        display: flex;
        gap: 20px;
    }

    &-TopMenuWrapper {
        color: $font-color-1;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        gap: 30px;
        list-style: none;
        margin: 0 auto;
        max-width: var(--content-wrapper-width);
        padding: 0;
        width: 100%;

        @include mobile {
            font-size: 16px
        }

        &_isCheckout {
            justify-content: start;

            @include desktop {
                padding: 0 32px;
            }
        }
    }

    &-IconsWrapper {
        grid-column: 3 / 4;
    }

    &-LogoWrapper {
        min-height: var(--header-logo-height);
        min-width: var(--header-logo-width);

        h1 {
            display: flex;
            height: 100%;
        }

        @include desktop {
            height: 84px;
        }
    }

    &-MinicartButtonWrapper {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        gap: 10px;
        margin-left: 0 !important;

        @include mobile {
            font-size: 16px
        }

        svg {
            min-width: 25px;
        }
    }

    &-MinicartItemCount {
        background: $primary-color-1;
        transform: translateX(-5px);
    }

    &-Nav {
        height: auto;
        gap: 20px;
        grid-template-columns: var(--header-logo-width) 1fr 93px;
        margin-top: 25px;

        @include desktop {
            padding-left: 0;
        }

        @include mobile {
            gap: 0;
            min-height: 44px;
            margin-top: 0;
        }
    }

    &_name_default,
    &_name_search {
        .Header-Nav {
            @include mobile {
                flex-direction: column;
                margin-top: 25px;
            }
        }
    }

    &-TopMenu {
        align-items: center;
        background: $neutral-color-7;
        color: $font-color-1;
        height: 48px;
        max-width: 100%;
        padding-top: 0;

        a {
            color: $font-color-1;
        }
    }

    &_name_popup {
        .Header-Button_type_close {
            @include mobile {
                position: absolute;
                right: 20px;
                top: 8px;
            }
        }

        .Header-Title {
            @include mobile {
                font-size: 27px;
            }
        }

        .Header-Nav {
            @include mobile {
                margin-bottom: 5px;
                margin-top: 5px;
            }
        }
    }

    >.PromoCounter {
        max-width: 100%;
    }
}
