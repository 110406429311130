/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ContactPage {
    @include mobile {
        margin-top: 0;
    }
    &-Column {
        &_isContent {
          @include mobile {
              margin-block-start: 32px;
            }
            @include desktop {
              margin-inline-start: 32px;
            }
        }
    }
    &-Heading {
      color: $font-color-1;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
      height: initial;

      @include mobile {
          font-size: 36px;
          line-height: 44px;
      }
    }
}
