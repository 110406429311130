.PromoCounter {
    display: none;
    background-color: $primary-color-1;
    justify-content: center;

    @include mobile {
        min-height: var(--promo-bar-mobile-height);
    }
    
    .CmsBlock-Wrapper {
        gap: 25px;
        flex-wrap: wrap;
        max-width: 100%;
        padding: 10px 32px;
        display: flex;
        justify-content: center;

        p {
            margin-block-end: 0;
        }

        @include mobile {
            gap: 6px;
            flex-direction: column;
            align-items: center;
            padding-block: 14px;
        }
    }
    
    &_isOpen {
        display: flex;
    }
    &-Component {
        margin-block: auto;
    }
    &-Slogan {
        color: $white;
        font-size: 17px;
        font-weight: 700;
        line-height: 20px;
        margin-block: auto;
        text-transform: uppercase;
        font-family: $font-open-sans;
    }

    &-Button {
        height: 30px;
        padding: 6px 12px;
        margin-block: auto;
        color: $primary-color-1;
        background-color: $white;

        &:hover {
            color: $white;
            background: $primary-color-1;
        }

        @include mobile {
            min-height: 30px;
            height: 30px;
            font-size: 16px;
        }
    }

    &-CloseButton {
        right: 30px;
        color: $white;
        line-height: 0.9;
        cursor: pointer;
        font-size: 26px;
        position: absolute;
        top: calc(50% - 13px);
        
        @include mobile {
            right: 7px;
            top: 8px;
        }
    }
}
