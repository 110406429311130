/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.PaginationLink {
    border: 2px solid $font-color-1;
    border-radius: 4px;
    color: $font-color-1;
    font-size: 16px;
    font-weight: 600;
    margin-inline-end: 24px;

    &:hover {
        color: $primary-color-1;
        border-color: $primary-color-1;
        text-decoration: none;
    }
    
    &_isArrow:hover svg{
        path {
            stroke: $primary-color-1;
        }
    }

    &_isCurrent {
        color: $primary-color-1;
        border-color: $primary-color-1;
    }
}
