/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Footer {
    &-CopyrightContentWrapper {
        background-color: $white;
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: $extra-color-7;
    }

    &-Columns {
        gap: 5%;

        @include desktop {
            padding-block-start: 0;
        }

        @include tablet {
            flex-direction: column;
        }

        @include mobile {
            padding-inline: 14px;
        }

        >.NewsletterSubscription {
            @include desktop {
                border-radius: 0 0 8px 8px;
            }

            &::before {
                @include desktop {
                    content: '';
                    display: block;
                    background: $extra-color-1;
                    position: absolute;
                    top: -16px;
                    height: 16px;
                    z-index: 2;
                    width: 100%;
                    left: 0;
                    border-radius: 8px 8px 0 0;
                }
            }

            .Field_type_checkbox {
                margin-bottom: 18px;


                +.Field-ErrorMessages {
                    position: absolute;
                    bottom: -22px;
                }
            }

            .Button {
                margin-top: 20px;
            }
        }
    }

    &-Checkout {
        background: $extra-color-7;
    }

    &-HideLayout {
        background-color: transparent;
        max-width: 1088px;
        margin: 0 auto;
        padding: 15px 0;
        border-top: 1px solid $neutral-color-15;

        @include mobile {
            max-width: calc(100% - 72px);
        }
    }

    &-ExpandableContentHeading {
        font-weight: 600;
        font-size: 18px;
        color: $font-color-1;
        line-height: 24px;
        margin-block-end: 16px;

        @include desktop {
            margin-block-end: 20px;
        }

        @include mobile {
            margin: 0;
        }
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }
    }

    &-ExpandableContentButton {
        @include mobile {
            cursor: pointer;
            padding-block: 16px;
        }
    }

    &-Column {
        &.ExpandableContent {
            @include mobile {
                border: 0;
                margin: 0;
            }

            &:not(:last-of-type) {
                @include mobile {
                    margin: 0 0 14px 0;
                }
            }

            &:last-of-type {
                @include mobile {
                    margin-block-end: 32px;
                }
            }
        }
    }

    .ColumnWrapper {
        padding: 50px 0 77px;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0;

        }
    }

    &-ColumnItem {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $font-color-2;


        @include mobile {
            text-align: left;
            margin-bottom: 10px;
            color: $extra-color-1;
            font-size: 16px;
        }
    }

    &-CopyrightContent {
        justify-content: space-between;
        padding-inline: 32px;
        padding-block: 27px;
        margin-block-end: 30px;

        @include tablet {
            flex-direction: column;
        }
    }

    &-Copyright {
        padding-inline-start: 0;
        padding-inline-end: 10px;
        font-size: 14px;
        color: $font-color-2;

        @include mobile {
            font-size: 16px
        }

        @include tablet {
            padding-inline-end: 0;
        }
    }

    .Row-OpenHours {
        p {
            @include mobile {
                text-align: left;
            }
        }
    }
}