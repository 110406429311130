.OurBookstores {
    h1 {
        margin-block-end: 40px;

        @include mobile {
            font-size: 28px;
            font-weight: 600;
            line-height: 30px
        }
    }

    @include mobile {
        padding: 0 16px;
    }
}

