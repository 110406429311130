/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Loader {
    &-Main {
        border-left: solid 5px $primary-color-1 !important;
        border-right: solid 5px $primary-color-1 !important;

        &::after {
            border-bottom: solid 7px $primary-color-1;
            border-left: solid 7px $primary-color-1;
        }

        &::before {
            border-top: solid 7px $primary-color-1;
            border-right: solid 7px $primary-color-1;
        }
    }
}