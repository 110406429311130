.SliderBottomBar {
    display: none;

    @include desktop {
        display: block;
        margin-bottom: 57px;
        padding: 18px 20px 22px;
        background-color: $neutral-color-9;
    }

    &-Wrapper {
        width: 1151px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    &-Item {
        display: flex;
        color: $font-color-2;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        @include mobile {
            font-size: 16px
        }

        svg, img {
            margin-right: 16px;
        }

        img {
            max-height: 38px;
            width: auto;
        }

        &:last-child {
            margin-right: 0;
        }

        > span {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            p {
                display: inline-flex;
                color: $font-color-2;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0;

                @include mobile {
                    font-size: 16px
                }
            }
        }

    }

    @media (min-width: 810px) and (max-width: 1190px) {
        padding: 14px 26px 16px;

        &-Wrapper {
            width: auto;
        }

        &-Item {    
            svg, img {
                margin-right: 8px;
            }
        }
    }
}
