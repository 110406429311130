/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CheckOrderPage {
    .ContentWrapper {
        padding-block-start: 28px;
        padding-block-end: 50px;
        padding-inline: 20px;

        @include desktop() {
            padding-block-start: 25px;
        }

        @supports (-webkit-touch-callout: none) {
            padding-block-end: 80px;
        }
    }

    &-InnerWrapper {
        @include desktop() {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 auto;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            margin-block-start: 20px;
        }

        button,
        input {
            min-width: 250px;

            @include mobile {
                width: 100%;
            }

            @include desktop {
                width: 100%;
            }
        }

        p {
            margin-block-end: 20px;
        }
    }

    &-FormWrapper {
        display: flex;
        justify-content: center;
        margin: 40px 20px;
        width: 100%;

        .Form {
            width: 100%;
            max-width: 500px;
        }
    }

    .Loader {
        margin: 0;
    }
}
