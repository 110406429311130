/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

a {
    border: none;
    color: $extra-color-12;
    font-weight: 600;
    font-size: inherit;
    line-height: 20px;
    
    @include mobile {
        font-size: 16px
    }
    @include hoverable { 
        &:hover {
            color: $primary-color-1;
        }
    }
    
    &:active {
        color: $primary-color-1;
    }

    &.Link {
        &:hover,
        &:focus {
            padding-block-end: 3px;
        }
    }
}
