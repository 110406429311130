/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Counter {
    gap: 5px;
    display: flex;
    flex-wrap: wrap;
    font-weight: 400;
    margin-block: auto;
    justify-content: center;
    text-transform: uppercase;
    color: $white;

    div {
        display: flex;

        b {
            margin-right: 5px;
        }

        &:first-of-type {
            b {
                margin-left: 5px;
            }
        }
    }
}