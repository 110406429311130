.HighlightedCategories {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 100px;
    max-width: 1390px;
    margin-inline: auto;

    @include desktop {
        padding-inline: 15px;
    }

    @include mobile {
        gap: 12px;
        display: flex;
        overflow-x: scroll;
    }

    &-CategoryTile {
        text-decoration: none;
        padding: 0;
        margin: 0;
        border-radius: 4px;
        max-height: 405px;
        
        @include mobile {
            img {
                height: 200px;
                width: auto;
            }
            &:nth-child(2), &:nth-child(3) {
                flex: 1 1 calc(50% - 6px);
            }
    
            &:nth-child(1) {
                flex: 1 1 100%;
            }
        }
    }

    &-Desc {
        position: absolute;
        left: 12px;
        bottom: 12px;
        display: flex;
        flex-direction: column;

        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 9px;
            @include mobile {
                font-size: 16px
            }
        }

        a {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: $primary-color-1;
            text-decoration: none;
            text-transform: capitalize;
            display: flex;
            @include mobile {
                font-size: 16px
            }
            .RenderWhenVisible {
                display: inline-flex;
                margin-left: 3px;
                align-self: center;
                margin-bottom: 0;
            }

            &:hover {
                color: $extra-color-1;
                svg {
                    path {
                        stroke: $extra-color-1;
                    }
                }
            }
        }

        @include desktop {
            left: 30px;
            bottom: 30px;

            span {
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 19px;
            }
        }
    }
}
