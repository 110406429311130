/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.AmountDropdown {
    display: flex;
    margin-inline: 5px;
    min-width: 205px;
    gap: 8px;

    .Field {
        margin-block-start: 0;
    }

    select {
        max-height: 36px;
        padding: 6px 14px;
    }

    .ChevronIcon.ChevronIcon_direction_bottom {
        right: 15px;
    }

    .Typography_body2 {
        margin-block-end: 0;
    }

    @include mobile {
        display: none;
        min-width: 105px;
        margin-inline-start: 0;

        .Field-Wrapper.Field-Wrapper_type_select {
            margin-block: auto;
        }

        select {
            margin-inline-start: 0;
        }

        span:first-child {
            display: none;
        }
    }
}