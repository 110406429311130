/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.DeliveryLockerPopup {
    .Popup {
        &-Content {
            overflow-x: inherit;
            overflow-y: inherit;
        }
    }

    &-Error {
        font-size: 12px;
        font-weight: bold;
        margin-top: 16px;
    }

    &-List {
        margin: 16px 0;
        width: 100%;

        &_toSelect_,
        &_toSelect {
            border-color: $secondary-color-1;
        }
    }

    &-OrlenPoint {
        border: 1px $neutral-color-11 solid;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        padding: 8px;

        &:hover {
            border-color: $primary-color-1;
        }

        &::before {
            display: none;
        }

        &_isSelected {
            border-color: $primary-color-1;
            border-width: 2px;
            padding: 7px;
        }

        button {
            cursor: pointer;
            font-size: 16px;
            text-align: left;
            width: 100%;
        }
    }

    &-OrlenPointsList {
        margin-top: 4px;
        margin-bottom: 16px;
        max-height: 160px;
        overflow: hidden;
        overflow-y: auto;
    }

    &-Title {
        color: grey;

        @include mobile {
            margin-top: 10px;
        }
    }

    &-ZipCodeField {
        input {
            width: 100%
        }
    }
}