/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --table-body-border: 1px solid $font-color-1;
}

table {
    thead th {
        background-color: $neutral-color-13;
        border-radius: 2px;
    }

    tbody tr:not(:last-child) {
        border-block-end: var(--table-body-border) ;
        border-bottom: 1px solid $neutral-color-13;
    }

    tr {
        page-break-inside: avoid;
    }

    td,
    th {
        padding: 10px;
        text-align: start;
        min-width: 120px;
        vertical-align: top;

        @include mobile {
            min-width: 140px;
        }
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}
