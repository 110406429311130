/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyBooks {
    background: url('/assets/img/my-books-bg-desktop.svg');
    background-color: $secondary-color-6;
    background-size: cover;
    margin-top: -70px;
    min-height: 526px;

    @include mobile {
        background: url("/assets/img/my-books-bg-mobile.svg");
        background-color: $secondary-color-6;
        margin-top: 0;
        padding-top: 37px;
    }

    .FieldSelect {
        &-Option {

            &:first-of-type {
                display: none;
            }

            &:nth-of-type(2) {
                margin-top: 12px;
            }
        }

        &-Options {
            border: 1px $neutral-color-4 solid;
            border-top: none;
            border-radius: 0 0 4px 4px;
        }
    }

    &-SearchButton {
        grid-column: 2/3;

        @include mobile {
            margin-bottom: 59px;
            margin-top: 20px;
        }
    }

    &-Selects {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(3, 1fr);
        max-width: 1100px;
        row-gap: 30px;
        width: 100%;

        @include mobile {
            display: flex;
            flex-direction: column;
            row-gap: 0;

            .Field {
                margin-top: 10px;
            }
        }
    }

    &-Subtitle {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;

        @include mobile {
            color: $font-color-2;
            font-size: 16px;
            line-height: 20px;
        }
    }

    &-Title {
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 60px;

        @include mobile {
            font-size: 20px;
            line-height: 26px;
            text-align: center;
        }
    }

    &-Wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        min-height: 526px;
    }
}
