/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-gray: #{$font-color-2};
    --color-dark-gray: #{$font-color-1};
    --color-karaka: black;
    --primary-divider-color: #{$neutral-color-6};
    --secondary-error-color: white;
    --primary-error-color: #{$error-color};
    --secondary-success-color: #fff;
    --primary-success-color: #{$success-color};
    --secondary-info-color: var(--color-karaka);
    --primary-info-color: #ffec51;
    --body-content-color: var(--color-black);

    // Placeholders
    --placeholder-gradient-color: #{$neutral-color-5};
    --placeholder-image: linear-gradient(to right, #{$neutral-color-6} 0, var(--placeholder-gradient-color) 15%, #{$neutral-color-6} 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    --shake-animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Scandipwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    --primary-base-color: var(--imported_primary_base_color, #{$primary-color-1});
    --primary-dark-color: var(--imported_primary_dark_color, #{$primary-color-2});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
}