.Typography_p1 {
    font-family: $font-open-sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $font-color-2;
    @include mobile {
        font-size: 16px
    }
}

.Typography_h1 {
    font-family: $font-open-sans;
    font-weight: 600;
    font-size: 42px;
    line-height: 50px;
}

.Typography_h3 {
    font-family: $font-open-sans;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
}

.Typography_h6 {
    font-family: $font-open-sans;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $font-color-1;  
}

.Typography_body2 {
    font-family: $font-open-sans;
    font-size: 16px;
    line-height: 24px;
    color: $font-color-2;
    margin-block-end: 40px;
}

.Typography_subhead1 {
    font-family: $font-open-sans;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $white;
}

.Typography_subhead2 {
    font-family: $font-open-sans;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    @include mobile {
        font-size: 16px
    }
}
