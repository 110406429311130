/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.SearchField {
    margin: 0 auto;
    max-width: 840px;

    @include mobile {
        margin: 0 0;
        max-width: 100%;
    }

    &-Input {
        font-size: 16px;
        height: 44px;
        padding: 10px 14px;

        &::placeholder {
            font-size: 16px;
        }
    }

    &-SearchIcon {
        align-items: center;
        background: $primary-color-1;
        bottom: 0;
        border-radius: 4px;
        display: flex;
        height: auto;
        justify-content: center;
        position: absolute;
        right: 0 !important;
        top: 0;
        width: 44px;

        svg {
            fill: $white;
        }
    }

    &-CloseIcon {
        align-items: center;
        background: $primary-color-1;
        bottom: 0;
        border-radius: 4px;
        display: flex;
        height: auto;
        justify-content: center;
        position: absolute;
        right: 0 !important;
        top: 0;
        width: 44px;

        svg {
            margin-top: 2px;

            path {
                stroke: $white;
            }
        }
    }

    &-Wrapper {
        margin: 20px 0;
    }
}