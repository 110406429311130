/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin check($mobile, $desktop) {
    a {
        align-items: center;
        color: $extra-color-1;
        cursor: pointer;
        display: $desktop;
        font-size: 14px;
        font-weight: 700;
        gap: 3px;
        text-transform: uppercase;
        @include mobile {
            font-size: 16px
        }
        @include mobile {
            display: $mobile;
        }

        svg {
            height: 18px;
            width: 18px;

            path {
                stroke: $extra-color-1;
            }
        }
    }
}

.SchoolClasses {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 68px;

    @include mobile {
        align-items: flex-start;
        margin-top: 30px;
    }

    &-Box {
        align-items: center;
        background: $secondary-color-1;
        border-radius: 4px;
        color: $neutral-color-12;
        display: flex;
        font-size: 57px;
        font-weight: 800;
        height: 70px;
        justify-content: center;
        width: 70px;
    }

    &-Header {
        display: flex;
        flex-direction: column;
        gap: 4px;

        h5 {
            font-size: 20px;
            font-weight: 600;

            @include mobile {
                line-height: 20px;
                text-align: center;
            }
        }

        @include check(none, flex);
    }

    &-LeftContent {
        align-items: center;
        display: flex;
        gap: 20px;

        @include mobile {
            flex-direction: column;
            gap: 10px;
            margin-bottom: 10px;
        }
    }

    &-List {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        list-style: none;
        margin: 40px 0 116px;
        width: 100%;

        @include mobile {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin: 20px 0 53px;
        }

        &Item {
            background-color: $neutral-color-9;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            max-width: 900px;
            padding: 20px 22px;
            width: 100%;

            @include mobile {
                align-items: center;
                flex-direction: column;
                padding: 15px 12px;
            }

            &::before {
                display: none;
            }

            .Button {
                height: 36px;
                color: white;
                font-size: 14px;
                font-weight: 700;
                padding: 0 20px;
                @include mobile {
                    font-size: 16px
                }
            }
        }
    }

    &-Localisation {
        align-items: center;
        display: flex;
        gap: 10px;
        margin-top: 10px;

        @include mobile {
            align-items: flex-start;
            flex-direction: column;
            font-size: 16px;
            gap: 6px;
        }

        span {
            background: $neutral-color-10;
            border-radius: 9px;
            height: 9px;
            width: 9px;

            @include mobile {
                height: 0;
                width: 0;
            }
        }
    }

    &-Price {
        font-size: 16px;

        @include mobile {
            font-size: 16px;
        }

        b {
            font-size: 24px;
            font-weight: 600;
            margin-left: 4px;

            @include mobile {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }

    &-RightContent {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;

        @include mobile {
            align-items: center;
        }

        @include check(flex, flex);
    }

    &-SchoolName {
        font-size: 42px;
        font-weight: 600;

        @include mobile {
            font-size: 18px;
            line-height: 24px;
        }
    }
}
