.ContactsFooter {
    &-Row {
        display: flex;
        gap: 14px;

        &-Icon{
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
        }

        .Typography_p1 {
            strong {
                display: inline-flex;
                align-items: center;

                span {
                    font-size: 9px;
                    position: relative;
                    top: -3px;
                }
            }

            @include mobile {
                color: $extra-color-1;
                font-size: 16px;
            }
        }
    }

    .Row-Mail {
        margin-block-end: 35px;

        .Mail-Icon {
            background-image: url("../../assets/icons/mail-gray.svg");

            @include mobile {
                background-image: url("../../assets/icons/mail-blue.svg");
            }
        }
    }

    .Row-Telephone .Telephone-Icon {
        background-image: url("../../assets/icons/phone-gray.svg");

        @include mobile {
            background-image: url("../../assets/icons/phone-blue.svg");
        }
    }
}
