.RecommendedCategories {
    width: 100%;
    padding: 40px;
    background-color: $extra-color-7;
    margin-bottom: 40px;

    &.full-width {
        @include mobile {
            width: 100vw;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        margin: 0 0 50px;
        padding: 0;

        @include mobile {
            text-align: left !important;
        }
    }

    @include mobile {
        padding: 20px 14px 14px;

        h2 {
            font-size: 24px;
            line-height: 30px;
            margin: 0 0 30px;
        }
    }

    &-Wrapper {
        @include desktop {
            max-width: var(--content-wrapper-width);
            padding-left: 32px;
            padding-right: 32px;
            margin-left: auto;
            margin-right: auto;    
        }
    
    }

    &-ListWrapper {
        display: flex;
        justify-content: space-between;

        @include mobile {
            overflow-x: auto;
            padding-bottom: 20px;

            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            touch-action: manipulation;

            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }
        }
    }

    &-List {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        span, div {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 20px;
            color: $font-color-1;
        }

        li {
            margin: 0 0 8px;

            &::before {
                display: none;
            }

            a {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $font-color-2;
            }
        }

        @include mobile {
            min-width: 300px;

            span, div {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 16px;
            }

            li {
                a {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}
