/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ContactForm {
    &-Captcha {
        margin: 24px 0;
    }

    .Form {
        textarea {
            font-size: 16px;
        }
        .Button {
            width: 100%;
        }
        
        .FieldGroup-Wrapper_contact {
            .FieldGroup {
                @include desktop {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    grid-column-gap: 32px;
                    margin-top: 24px;
                }
            }

            .Field-Wrapper {
                &_type_email .Field {
                    @include mobile {
                        margin-top: 14px;
                    }
                }
                &_type_tel .Field {
                    @include desktop {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
