.Cms_SeoBlock,
.Cms_CategorySeoBlock {
    max-width: 1390px;
    margin: 0 auto;
    padding-top: 110px;
    padding-bottom: 100px;

    @include desktop {
        padding-inline: 15px;
    }

    @include mobile {
        margin-inline: 14px;
        padding-bottom: 20px;
        padding-top: 0;
    }

    .Typography_h6 {
        margin-block-end: 8px;
    }

    p {
        font-weight: 400;
    }
}
