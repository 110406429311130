/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Breadcrumbs {
    &-List {
        padding: 0;

        @include mobile {
            display: flex;
            flex-wrap: wrap;
            padding: 12px 0 0;
        }
    }

    @include mobile {
        display: block;
        min-height: 51px;

        &_default {
            margin-top: calc(var(--header-total-height) + 84px);
        }

        &-List {
            &_isCategory {
                margin-top: calc(var(--header-total-height) + 44px);
            }
        }
    }

    &_isContactPage,
    &_isMyAccount {
        @include mobile {
            margin-top: calc(var(--header-total-height) + 10px);
        }
    }
}
