/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.NewsletterSubscription {
    min-width: 230px;
    background: $extra-color-1;
    border-radius: 8px;
    padding: 16px;

    & & {
        padding: 0;
    }

    @include tablet {
        width: 100%;
        max-width: 100%;
        padding: 25px 50px;
    }

    &_isCoupon {
        border-radius: 12px;
        padding: 30px 27px;
        max-width: 551px;

        @include mobile {
            min-width: unset;
            margin: 0 36px;
            width: auto;
            padding: 23px 19px;
        }

        &,
        .NewsletterSubscription {
            background: $secondary-color-7;
        }

        .NewsletterSubscription-Wrapper {
            max-width: unset;
        }

        .NewsletterSubscription-Title {
            margin-bottom: 14px;

            .Typography_h3 {
                text-align: center;
                font: normal normal bold 22px/30px 'Open Sans';
                letter-spacing: 0px;
                color: $extra-color-11;

                @include mobile {
                    font: normal normal bold 23px/30px 'Open Sans';
                }
            }
        }

        .FieldForm-Fields {
            @include desktop {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .FieldForm-Fieldset .Field-Wrapper.Field-Wrapper_type_email {
            @include mobile {
                margin-bottom: 25px;
                margin-right: 0;
            }

            @include desktop {
                width: 100%;
                max-width: 353px;
                margin-right: 10px;
                margin-bottom: 21px;
            }

            .Field label {
                margin-top: 0;
                font: normal normal normal 14px/22px 'Open Sans';
                letter-spacing: 0px;
                color: $extra-color-11;
                padding-bottom: 8px;

                @include mobile {
                    font: normal normal normal 10px/18px 'Open Sans';
                    padding-bottom: 5px;
                }
            }

            input {
                border: 2px solid $secondary-color-8;

                @include mobile {
                    height: 36px;
                }

                @include desktop {
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                }
            }
        }

        .Field-ErrorMessage {
            position: absolute;

            @include mobile {
                font-size: 12px !important;
            }
        }

        .Field-ErrorMessages {
            margin-top: 0;
        }


        .FieldForm-Fieldset label.Field-CheckboxLabel {
            align-items: flex-start;

            div {
                font: normal normal normal 12px/18px 'Open Sans';

                @include mobile {
                    font: normal normal bold 10px/15px 'Open Sans';
                }
            }

            @include desktop {
                margin-top: 0 !important;
            }

            a {
                z-index: 10;
                font: normal normal bold 12px/18px 'Open Sans';
                font-weight: bold;

                @include mobile {
                    font: normal normal bold 10px/15px 'Open Sans';
                }
            }

            .input-control {
                top: 2px;
            }
        }

        .FieldForm-Fieldset .Field-Wrapper.Field-Wrapper_type_submit {
            @include mobile {
                text-align: center;
            }

            input {
                height: 40px;
                padding: 9px 25px;
                background: $primary-color-7 0% 0% no-repeat padding-box;
                border: 1px solid $primary-color-8;
                border-radius: 4px;
                text-align: center;
                font: normal normal bold 14px/21px 'Open Sans';
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                cursor: pointer;
                transition-duration: .25s;
                transition-timing-function: ease-out;
                transition-property: background-color, color, border;
                will-change: background-color, color, border;

                &:hover,
                &:focus {
                    background-color: $primary-color-2
                }

                @include mobile {
                    padding: 9px 35px;
                    font: normal normal bold 14px/17px 'Open Sans';
                    height: 36px;
                    width: auto;
                }

                @include desktop {
                    top: 30px;
                }
            }
        }

        .FieldForm-Fieldset .Field-Wrapper.Field-Wrapper_type_checkbox {
            @include desktop {
                width: 100%;
            }

            @include mobile {
                margin-bottom: 4px;
            }

            .Field-ErrorMessages {
                margin-top: 0;
            }

            .Field-CheckboxLabel {
                letter-spacing: 0px;
                color: $extra-color-11;
            }

            .input-control {
                background-color: transparent;
                width: 14px;
                height: 14px;
                min-width: 14px;
                min-height: 14px;
                border: 1px solid $extra-color-11;
                border-radius: 0;
            }
        }
    }

    &-Wrapper {
        max-width: 400px;
        margin: 0 auto;
    }

    &-Title {
        margin-block-end: 12px;

        .Typography_h3 {
            color: $white;
            text-align: center;
        }
    }

    &-Icon {
        width: 116px;
        height: 116px;
        margin-inline: auto;
        margin-block-end: 15px;

        @include mobile {
            display: none;
        }
    }

    .FieldForm {
        &-Fieldset {
            .Field {
                label {
                    color: $white;
                    font-family: $font-open-sans;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    margin-block-start: 20px;
                    text-align: left;
                }

                input {
                    background-color: $white;
                }

                .input-control {
                    min-width: 20px;
                }
            }
        }
    }
}
