/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// imported here because it is needed only on coupon landing page - not whole app
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

.CouponNewsletterForm {
    &-Image {
        position: absolute;
        top: -60px;
        height: auto;
        max-width: 896px;
        right: -140px;
        width: 100%;

        @include mobile {
            top: 50px;
            right: 0;
        }
    }

    &-Text {
        margin-top: 60px;

        @include mobile {
            padding: 0 36px;
            margin-top: 215px;
        }
    }

    &-Heading {
        font: normal normal bold 45px/55px 'Open Sans';
        letter-spacing: 0px;
        color: $extra-color-11;

        @include mobile {
            font: normal normal bold 28px/35px 'Open Sans';
        }

        &Accent {
            font: normal normal bold 80px/90px 'Open Sans';
            letter-spacing: 0px;
            color: $primary-color-7;

            @include mobile {
                font: normal normal bold 55px/65px 'Open Sans';
            }
        }
    }

    &-IntroText {
        text-align: center;
        font: normal normal 400 14px/24px 'Open Sans';
        letter-spacing: 0px;
        color: $neutral-color-16;
        max-width: 551px;
        margin: 35px auto 12px 0;

        @include mobile {
            font: normal normal normal 12px/20px 'Open Sans';
            margin: 40px auto 15px 0;
            text-align: left;
        }
    }

    &-Terms {
        text-align: center;
        letter-spacing: 0px;
        color: $neutral-color-16;
        margin: 50px auto;

        @include desktop {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        >div {
            @include desktop {
                margin-right: 15px;
                font: normal normal 400 14px/30px 'Open Sans';
            }
        }

        a {
            font: normal normal bold 14px/22px 'Lato';
            letter-spacing: 0px;
            color: $extra-color-11;
            text-transform: uppercase;

            @include desktop {
                font: normal normal bold 16px/30px 'Lato';
            }
        }
    }

    &-ScrollTo {
        display: flex;
        margin: 50px auto 0;

        @include mobile {
            flex-direction: column;
            align-items: center;
        }

        @include desktop {
            align-items: center;
            justify-content: center;
        }

        p {
            color: $extra-color-11;
            font: normal normal 400 24px/52px 'Open Sans';

            @include desktop {
                margin-bottom: 0;
                margin-right: 35px;
                font: normal normal 400 30px/52px 'Open Sans';
            }

            strong {
                color: $extra-color-11;
                font: normal normal bold 24px/52px 'Open Sans';

                @include desktop {
                    font: normal normal bold 30px/52px 'Open Sans';
                }
            }
        }

        a {
            @include button();
            height: 40px;
            min-height: 40px;
            font: normal normal bold 22px/21px 'Open Sans';
            min-width: 246px;

            @include mobile {
                max-width: 246px;
            }
        }
    }
}
