/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

:root {
    --content-scroll-offset-y: 44px;
}

.ExpandableContent {
    scroll-margin-top: var(--content-scroll-offset-y);

    &-Heading {
        font-weight: 400;
        font-size: 16px;
        color: $font-color-1;
    }

    &-Button {
        &_activeOnMobileAndDesktop:not(.ExpandableContent-Button_isContentExpanded) {
            +.ExpandableContent-Content {
                max-height: 0;
                overflow: hidden;
                opacity: 0;
                display: none;
            }
        }

        @include mobile {
            padding-block: 16px;
        }
        
    }

    &-Content {
        &_expandable {
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &.ExpandableContent-Content_isContentExpanded {
                @include expanded-content;
            }
        }
    }

    @include mobile {
        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }
}
