.HideLayoutFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
        align-items: flex-start !important;
        flex-wrap: wrap;
    }

    &-Links {
        display: flex;
        gap: 2px;

        @include mobile {
            order: -1;
            min-width: 100%;
            margin-bottom: 10px;
        }

        a {
            transition-duration: .25s;
            transition-timing-function: ease-out;
            transition-property: background-color, color, border;
            will-change: background-color, color, border;

            &:hover,
            &:focus {
                color: $primary-color-1;
            }
        }
    }

    a:first-of-type {
        margin-right: 10px;
        &::after {
            content: "|";
            position: absolute;
            right: -9px;
            bottom: 1px;
            color: $neutral-color-15;
        }
    }

    .Typography_p1 {
        font: normal normal normal 13px/44px 'Open Sans';
        letter-spacing: 0px;
        color: $neutral-color-15;

        @include mobile {
            line-height: unset;
        }
    }
}
