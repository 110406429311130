/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.Pagination {
    @include mobile {
        margin-block-start: 20px;
        padding-bottom: 40px;
        display: flex;

        &-ListItem {
            .PaginationLink {
                margin-right: 10px !important;
            }
        }
    }

    @include desktop {
        margin-block-start: 20px;
    }

    .PaginationLink_dotted {
        @include mobile {
            border: none;
            line-height: 1px;
            top: 10px;
            padding-inline: 5px;
        }
    }

    .ChevronIcon {
        cursor: pointer;
        display: block;
        height: 20px;
        width: 16px;

        &:hover,
        &:focus {
            fill: none;
        }
    }
}