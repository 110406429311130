/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Menu {
    &-Item {
        &:nth-last-child(3) {
            margin-left: auto !important;
        }
    }

    &-ItemCaption {
        color: $font-color-1;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 0 !important;

        &:hover {
            color: $primary-color-1;
        }


        &_type_subcategory {
            font-weight: 400;
            margin-top: 0;
        }

        &_isPrimary {
            color: $primary-color-1;
        }

        @include mobile {
            font-size: 18px;

            &_isSecondLevel {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-left: 0;
                padding-top: 0 !important;
            }
        }
    }

    &-ItemList {
        @include mobile {
            padding: 10px 0;
        }
    }

    &-Link {
        font-weight: 400 !important;
    }

    &-MainCategories {
        margin: inherit;
        width: 100%;
    }

    &-MenuWrapper {
        display: flex;
    }

    &-Sub {
        &Categories {
            min-width: 360px;
            padding: 10px 10px 20px;

            .Menu {
                &-ItemList {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    padding: 0;
                }
            }
        }

        &CategoriesWrapper {
            background: $white;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
            border-radius: 0px 0px 12px 12px;
            left: 0;
            margin-top: -1px;
            position: absolute;
            top: 31px;
        }

        &ItemWrapper {
            border-radius: 4px;
            cursor: pointer;
            padding: 2px 10px;

            &:hover {
                background: $extra-color-7;
            }

            @include mobile {
                padding: 5px 10px;
            }
        }

        &Menu {
            margin: 0;

            @include mobile {
                background: $extra-color-7;
                margin: 10px 16px 0;
                padding: 0;

                .Menu-Link {
                    &_isHovered {
                        .Menu-ItemCaption {
                            margin-bottom: 5px;
                            margin-top: 5px;
                            padding: 10px 0 0 10px;
                        }
                    }
                }
            }
        }
    }
}