/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.AddressFilter {
    &-Heading {
        padding-block-end: 20px;
    }
    &-Form {
        display: flex;
        gap: 35px;
        justify-content: space-between;
        width: 100%;

        @include desktop {
            min-width: 500px;
        }
        .Field-Wrapper, .Field, div {
            width: 100%;
        }
        label {
            width: 100%;
            padding: 20px;
        }
    }
    &-RadioBtn {
        border: 1px solid $neutral-color-5;
        font-size: 18px;
        border-radius: 4px;
        width: 100%;
        margin-top: 0;
        &:hover {
            border: 1px solid $primary-color-1;
        }
        &_checked  {
            border: 1px solid $primary-color-1;
        }
    }
    @include tablet {
        &-Form {
            gap: 20px;
            flex-wrap: wrap;
        }
    }
}
