.Cms_CategoryBanner {
    &-Mobile {
        padding-inline: 14px;
        @include desktop {
            display: none;
        }
    }

    &-Desktop {
        @include mobile {
            display: none;
        }
    }
}