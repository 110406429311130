.faq-content {
    max-width: var(--content-wrapper-width);
    padding-inline: 32px;
    margin-inline: auto;

    @include mobile {
        padding-inline: 14px;
        margin-bottom: 214px;
    }

    @include desktop {
        display: flex;
        align-items: flex-start;
        column-gap: 48px;
    }

    > .widget {
        @include desktop {
            position: sticky;
            top: 20px;
        }
    }

    .FaqIntro {
        margin-bottom: 40px;

        @include mobile {
            margin-top: 40px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 6px;
        }
    }

    .FaqNavigation {
        background: $white;
        border: 1px solid var(--secondary-base-color);
        border-radius: 2px;
        padding: 24px 16px;

        @include desktop {
            width: 25%;
            min-width: 270px;
        }

        li {
            margin: 0 0 8px;

            &:last-child {
                margin: 0;
            }

            &::before {
                display: none;
            }

            button {
                display: inline-block;
                cursor: pointer;
            }

            a {
                font-size: 14px;
            }
        }

        &-Title {
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: $font-color-1;
            border-bottom: 1px solid var(--primary-divider-color);
            padding-bottom: 16px;
        }
    }
}
