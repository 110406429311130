/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// imported here because it is needed only on coupon landing page - not whole app
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

.CouponCodeInfo {
    &-Image {
        position: absolute;
        top: -32px;
        height: auto;
        margin: 0 -50%;
        min-width: calc(100% + 50%);
        left: 115px;

        @include mobile {
            top: -30px;
            left: 85px;
        }
    }

    &-Text {
        margin-top: 32px;
        text-align: center;

        @include mobile {
            padding: 0 36px;
            margin-top: 70px;
        }
    }

    &-Heading {
        font: normal normal bold 30px/52px $font-open-sans;
        letter-spacing: 0px;
        color: $extra-color-11;
        margin-bottom: 21px;

        +p {
            font: normal normal 400 16px/24px $font-open-sans;
            letter-spacing: 0px;
            color: $neutral-color-16;
            margin-bottom: 40px;

            @include mobile {
                margin-bottom: 22px;
            }

            strong {
                font: normal normal bold 16px/24px $font-open-sans;
                letter-spacing: 0px;
                color: $primary-color-7;
            }
        }
    }

    &-Content {
        @include mobile {
            padding: 0 36px;

        }
    }

    &-Code {
        background-color: #ffffff;
        padding: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        font: normal normal bold 40px/52px $font-open-sans;
        letter-spacing: 0px;
        color: $primary-color-7;
        border: 0.699999988079071px solid #DAE7FC;
        margin-bottom: 129px;

        @include mobile {
            font: normal normal bold 30px/52px $font-open-sans;
            padding: 23px;
            margin-bottom: 14px;
        }
    }

    &-UsageInfo {
        text-align: center;
        letter-spacing: 0px;
        margin: 68px 0 36px 0;
        padding: 19px;
        background: #F2F7FF 0% 0% no-repeat padding-box;

        p {

            color: #00183F;

            font: normal normal normal 16px/30px $font-open-sans;

            @include mobile {
                font: normal normal normal 16px/30px $font-open-sans;
            }

            strong {
                font: normal normal bold 14px/30px $font-open-sans;

                @include mobile {
                    font: normal normal bold 16px/30px $font-open-sans;
                }
            }
        }
    }

    &-UsageInfoItems {

        @include desktop {
            display: flex;
            justify-content: center;
            column-gap: 35px;
            margin-top: 21px;
        }

        >div {
            display: flex;
            align-items: center;
            flex-direction: column;

            &:first-child {
                @include mobile {
                    margin-bottom: 24px;

                }
            }
        }

        img {
            max-width: 72px;
            height: auto;
        }

        p {
            font: normal normal normal 14px/30px $font-open-sans;
            letter-spacing: 0px;
            color: $neutral-color-16;
            margin: 12px auto 0;
        }
    }

    &-Terms {
        text-align: center;
        letter-spacing: 0px;
        color: $neutral-color-16;
        margin: 50px auto;

        @include desktop {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        >div {
            @include desktop {
                margin-right: 15px;
                font: normal normal 400 14px/30px $font-open-sans;
            }
        }

        a {
            font: normal normal bold 14px/22px 'Lato';
            letter-spacing: 0px;
            color: $extra-color-11;
            text-transform: uppercase;

            @include desktop {
                font: normal normal bold 16px/30px 'Lato';
            }
        }
    }

    &-SecondaryActions {
        display: flex;
        column-gap: 16px;
        flex-wrap: wrap;
        justify-content: center;

        @include mobile {
            row-gap: 15px;
        }

        p {
            width: 100%;
            text-align: center;
            margin: 30px auto;
            font: normal normal normal 16px/24px $font-open-sans;
            letter-spacing: 0px;
            color: $neutral-color-16;

            @include mobile {
                margin: 10px auto 23px;

                font: normal normal normal 14px/22px $font-open-sans;
            }
        }

        button,
        a {
            display: inline-block;
            width: 184px;
            height: 40px;
            border: 1px solid #DDDDDD;
            border-radius: 4px;
            text-align: center;
            font: normal normal 400 14px/22px $font-open-sans;
            letter-spacing: 0px;
            color: $extra-color-11;
            text-transform: uppercase;
            cursor: pointer;
            line-height: 40px;
            background-color: $white;

            svg {
                margin-right: 9px;
                top: 2px;
            }
        }
    }

    &-PrimaryActions {
        text-align: center;
        margin-bottom: 37px;

        @include mobile {
            margin-bottom: 34px;
        }

        .Button {
            min-height: 40px;
            height: 40px;
            font: normal normal bold 18px/21px $font-open-sans;
            letter-spacing: 0;

            @include mobile {
                width: auto;
                padding-left: 16px;
                padding-right: 16px;
            }

            @include desktop {
                min-width: 246px;
            }
        }
    }

    &-EmailInfo {
        text-align: center;

        @include mobile {
            padding: 0 30px;
        }

        p {

            font: normal normal normal 16px/30px $font-open-sans;
            letter-spacing: 0px;
            color: $neutral-color-16;
            margin-bottom: 0;

            @include mobile {
                font: normal normal normal 14px/22px $font-open-sans;
            }
        }

        a {
            font: normal normal bold 16px/30px $font-open-sans;
            letter-spacing: 0px;
            color: $extra-color-11;

            @include mobile {
                font: normal normal bold 14px/22px $font-open-sans;
            }
        }
    }

    &-ScrollTo {
        display: flex;
        margin: 50px auto 0;

        @include mobile {
            flex-direction: column;
            align-items: center;
        }

        @include desktop {
            align-items: center;
            justify-content: center;
        }

        p {
            color: $extra-color-11;
            font: normal normal 400 24px/52px $font-open-sans;

            @include desktop {
                margin-bottom: 0;
                margin-right: 35px;
                font: normal normal 400 30px/52px $font-open-sans;
            }

            strong {
                color: $extra-color-11;
                font: normal normal bold 24px/52px $font-open-sans;

                @include desktop {
                    font: normal normal bold 30px/52px $font-open-sans;
                }
            }
        }

        a {

            @include button();
            height: 40px;
            min-height: 40px;
            font: normal normal bold 22px/21px $font-open-sans;
            min-width: 246px;

            @include mobile {
                max-width: 246px;
            }
        }
    }
}

.CmsPage .CouponCodeInfo-Heading {
    @include mobile {
        font: normal normal bold 28px/52px $font-open-sans;
    }
}
