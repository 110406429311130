/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

blockquote {
    line-height: 1.6;
    margin-block-end: 12px;
    padding-block-start: 6px;
    padding-inline-start: 14px;
    border-inline-start: $font-color-2;
    color: $font-color-2;
    font-size: 12px;

    @include mobile {
        margin-block-end: 14px;
        padding-block-start: 7px;
        padding-inline: 17px 17px;
        font-size: 16px;
    }
}
