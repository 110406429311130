/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Accordion {
    &-Header {
        color: $font-color-1;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        cursor: pointer;
        word-break: break-word;
        
        @include desktop {
            max-width: 765px;
        }
    }

    &-Title {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: $font-color-1;
        margin-top: 30px;
    }

    &-Box {
        margin-bottom: 20px;

        @include mobile {
            border: 0;
            margin-bottom: 0;
        }

        &:last-of-type {
            @include mobile {
                border: 0;
            }
        }

        .ExpandableContent-Button {
            cursor: pointer;
            display: inline-flex;
            width: auto;
            
            @include mobile {
                padding-block: 7px;
            }

            > svg {
                min-width: 24px;
            }
        }

        .ExpandableContent-Heading {
            margin-left: 5px;
        }

        .ExpandableContent-Content {
            @include desktop {
                margin-top: 6px;
            }
        }
    }

    &-Content {
        @include desktop {
            padding-left: 30px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            @include mobile {
                font-size: 16px
            }
        }
    }
}
