.Cms_PaymentMethods {
    height: 30px;
    display: flex;
    @include tablet {
        flex-direction: column;
        height: initial;
    }
    @include mobile {
        height: auto;
    }

    .DeliveryMethods, .PaymentMethods {
        display: flex;
        gap: 22px;
        &_Method {
            height: auto;
            display: flex;
            img {
                width: auto;
                height: auto;
                margin: auto 0;
            }
        }
        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .DeliveryMethods {
        border-right: 1px solid $neutral-color-4;
        padding-inline-end: 20px;
        margin-inline-end: 20px;
        @include tablet {
            border-right: none;
            justify-content: center;
            margin-block: 20px;
            max-width: 240px;
            padding-block: 20px;
            margin-inline-start: auto;
            margin-inline-end: auto;
            
            &::before, &::after {
                content: '';
                width: 30%;
                height: 1px;
                background-color: $neutral-color-4;
                position: absolute;
            }

            &::before {
                top: 0;
            }

            &::after {
                bottom: 0;
            }
        }
    }
}