/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.SearchOverlay {
    height: calc(100% - var(--header-total-height) - 70px) !important;
    margin-top: 70px;

    &-CategoriesList {
        display: flex;
        flex-direction: column;
        margin: 0 0 15px;
        list-style: none;

        &Item {
            font-size: 16px;
            margin: 0;
            padding: 5px 20px;

            &:hover {
                background-color: $extra-color-7;
            }
        }

        li {
            margin-bottom: 0;

            &::before {
                content: ""
            }
        }
    }

    &-CategoriesTitle {
        color: $font-color-1;
        font-size: 20px;
        font-weight: 600;
        margin: 0 20px 15px;
    }

    &-HorizontalLine {
        background-color: $neutral-color-6;
        height: 1px;
        margin: 15px 19px;
    }

    &-ItemsHolder {
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-Results {
        border-radius: 0px 0px 4px 4px;
        box-shadow: 2px 2px 18px rgba(0, 0, 0, 0.15);
        padding-top: 16px;
    }
}