/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.NewsSection {
    &-Header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @include mobile {
            margin: 0 14px 12px;
        }

        h2 {
            font-size: 36px;
            font-weight: 600;
            line-height: 42px;

            @include mobile {
                font-size: 24px;
                line-height: 30px;
            }
        }

        a {
            align-items: center;
            color: $extra-color-1;
            display: inline-flex;
            font-size: 14px;
            font-weight: 700;
            gap: 3px;
            line-height: 18px;
            text-transform: uppercase;

            @include mobile {
                font-size: 16px
            }

            &:hover {
                color: $primary-color-1;

                path {
                    stroke: $primary-color-1;
                }
            }

            svg {
                height: 18px;
                width: 18px;

                >* {
                    stroke: $extra-color-1;
                }
            }
        }
    }

    &-Post {

        &::before {
            display: none;
        }

        &Description {
            color: $font-color-2;
            display: -webkit-box;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            margin-top: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            @include mobile {
                font-size: 16px
            }
        }

        &Image {
            border-radius: 4px;
            object-fit: cover;
            max-height: 210px;
            width: 100%;

            &Container {
                display: flex;
                height: 210px;
                max-height: 210px;
                margin-bottom: 12px;
            }
        }


        &Title {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;

            @include mobile {
                font-size: 16px;
            }
        }
    }

    &-Posts {
        @include mobile {
            margin: 0 14px 35px;
        }
    }

    &-Wrapper {
        margin: 131px auto 0;
        padding: 0;

        @include mobile {
            margin: 60px auto 0;

            .slick-slide {
                padding: 0;
            }

            .slick-slider {
                margin-left: -15px;
                margin-right: -15px;
            }

            .slick-track {
                display: inline-flex;
                gap: 12px;
                padding-left: 3px;
                padding-right: 3px;
            }
        }
    }
}