/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.PickupFromBookstorePopup {

    &-Actions {
        display: grid;
        gap: 22px;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 20px;

        button {
            height: 48px;
        }
    }

    &-List {
        height: 600px;
        overflow-y: auto;

        @include mobile {
            height: calc(100% - 84px);
        }

        &Item {
            border: 2px solid $neutral-color-8;
            border-radius: 8px;
            margin-bottom: 12px;

            &_isSelected {
                border: 2px solid $primary-color-1;
            }

            & * {
                color: $font-color-1;
            }

            &Button {
                background: $neutral-color-8;
                border-radius: 8px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                padding: 20px;
                width: 100%;

                &_isUnavailable {
                    cursor: not-allowed;
                }
            }

            &Content {
                height: 100%;
                width: 100%;
            }

            &Header {
                display: flex;
                font-size: 16px;
                font-weight: 600;
                justify-content: space-between;

                .PickupFromBookstorePopup-ListItemStatus_isMobile {
                    display: none;
                }
            }

            &Main {
                font-size: 14px;
                margin-top: 6px;
                min-height: 0;
                text-align: left;

                .PickupFromBookstorePopup-ListItemStatus {
                    display: none;
                }

                @include mobile {
                    margin-bottom: 0;
                    font-size: 16px;

                    .PickupFromBookstorePopup-ListItemStatus {
                        display: flex;
                        font-weight: 600;
                        justify-content: flex-end;
                        margin-top: 4px;
                    }
                }
            }

            &Status {
                align-items: center;
                color: $extra-color-10;
                display: flex;
                font-size: 14px;
                gap: 4px;

                @include mobile {
                    font-size: 16px
                }

                &_isAvailable {
                    color: $extra-color-9;
                }

                &_isUnavailable {
                    color: $error-color;
                    text-align: right;
                    max-width: 243px;

                    svg {
                        flex: none;
                    }
                }
            }

            &::before {
                display: none;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-Popup {
        .Popup {
            &-CloseBtn {
                height: 24px;
                position: relative;
                right: 0 !important;
                top: 0 !important;
            }

            &-Content {
                max-height: 745px;
                min-height: 745px;
                padding: 20px;
                overflow: hidden;

                &::after {
                    display: none;
                }
            }

            &-Header {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
            }

            &-Heading {
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
            }
        }
    }

    &-SelectedBookstore {
        border: 2px solid $primary-color-1;
        border-radius: 4px;
        margin-top: 12px;
        padding: 16px 20px;

        &Details {
            display: flex;
            gap: 10px;
        }
    }
}
