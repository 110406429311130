/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Breadcrumb {
    padding-right: 0 !important;

    @include mobile {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        margin-bottom: 0 !important;

        &:last-of-type {
            margin-bottom: 14px;

            .Breadcrumb-Link {
                color: $font-color-1;
            }
        }
    }

    &-Link {
        color: $font-color-2;
        font-size: 14px;

        @include mobile {
            line-height: 12px;

            &-Name {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }

    &-Slash {
        color: $font-color-2;
        margin: 0 4px;

        @include mobile {
            font-size: 10px;
            line-height: 12px;
        }
    }
}