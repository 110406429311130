/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Field_type {
    &_numberWithControls {
        border-bottom: 2px solid $neutral-color-6;
        border-radius: 4px;
        border-top: 2px solid $neutral-color-6;
        cursor: default;
        display: flex;
        height: 38px;
        margin-block-start: 0;

        .AddIcon,
        .MinusIcon {
            height: 34px;
            width: 34px;
        }

        [type="number"] {
            font-size: 16px;
            font-weight: 600;

            &~button {
                border: 2px solid $extra-color-1;
                border-radius: 4px;
                height: 38px;
                width: 38px;

                &:hover {
                    border: 2px solid $primary-color-1;

                    path {
                        fill: $primary-color-1;
                    }
                }

                &:disabled {
                    border: 2px solid $neutral-color-6;
                    opacity: 1;

                    path {
                        fill: $neutral-color-6;
                    }
                }
            }

            @include mobile {
                align-items: center;
                font-size: 14px;
            }

            input {
                @include mobile {
                    width: 32px;
                    min-width: 32px;
                    background-color: transparent;
                }
            }

            button {
                &:not([disabled]) {
                    cursor: pointer;
                }
            }
        }

    }
}